import React from "react"
import styled from "styled-components"
import marked from "marked"

import Hero from "../components/Hero"
import Page from "../components/Page"
import MainWrapper from "../components/MainWrapper"
import { Default, Skewed } from "../components/PageSections"

import FAQ from "../components/page-specific/About/FAQ"
import SuperItem from "../components/page-specific/About/SuperItem"

import aboutData from "../data/about.json"

import { themer } from "../styles/helpers"

const  Superlatives = styled(Skewed)`
  padding: 10rem 0 3rem 0;
  margin: 10rem 0;
  &:before {
    background: ${themer("shade.lightest")};
  }
`

const HeyThere = styled(Skewed)`
  padding: 3rem 0 15rem;
  margin: 10rem 0;
  &:before {
    background: ${themer("shade.lightest")};
  }
`

const Questions = styled(Default)`
  color: ${themer("shade.lightest")};
  mark {
    padding: 0.5rem;
    color: ${themer("shade.lightest")};
  }
`

const SuperWrapper = styled(MainWrapper)`
  display: grid;
  padding: 0;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2rem 2rem;
`

const About = () => {
  return (
    <Page accentKey="red" bgDesign="snow" seoProfile="about-page">
      <Hero expanding height="50vh">
        <h1>What We're About</h1>
      </Hero>
      <HeyThere skew="-4deg">
        <h2 className="title">Origin Story</h2>
        <MainWrapper>
          <p
            className="markdown"
            dangerouslySetInnerHTML={{ __html: marked(aboutData.helloText) }}
          />
        </MainWrapper>
      </HeyThere>
      <Questions skew="4deg">
        <MainWrapper maxWidth="960px">
          <h2 className="title">FAQs</h2>
          {aboutData.faqs.map(({ question, answer }, i) => (
            <FAQ question={question} key={i}>
              <span dangerouslySetInnerHTML={{ __html: marked(answer) }} />
            </FAQ>
          ))}
        </MainWrapper>
      </Questions>
    </Page>
  )
}

export default About
